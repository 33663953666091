<template>
  <div>
    <!-- <el-upload
      :action="uploadUrl"
      :before-upload="handleBeforeUpload"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      name="file"
      :show-file-list="false"
      :headers="headers"
      style="display: none"
      ref="upload"
      v-if="this.type == 'url'"
    >
    </el-upload> -->
    <el-upload action :on-error="handleUploadError" name="file" :show-file-list="false" :headers="headers"
      style="display: none" ref="upload" :http-request="uploadFile" v-if="this.type == 'url'">
    </el-upload>
    <div class="editor" ref="editor" :style="styles" @paste="handlePaste($event)"></div>
  </div>
</template>

<script>
import { baseURL, postPic } from "../../api/http";
import { compressImage } from "../../modules/CompressImage";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { ImageDrop } from "quill-image-drop-module"; // 图片拖动组件引用
import ImageResize from "quill-image-resize-module"; // 图片缩放组件引用
import { ImageExtend } from 'quill-image-extend-module'; // 图片粘贴上传
Quill.register("modules/imageDrop", ImageDrop); // 注册
Quill.register("modules/imageResize", ImageResize); // 注册
Quill.register('modules/imageExtend', ImageExtend);
import Image from "./image";
Quill.register(Image, true);
import $ from 'jquery';

/*标题*/
const titleConfig = {
  'ql-bold': '加粗',
  'ql-font': '字体',
  'ql-code': '插入代码',
  'ql-italic': '斜体',
  'ql-link': '添加链接',
  'ql-color': '字体颜色',
  'ql-background': '背景颜色',
  'ql-size': '字体大小',
  'ql-strike': '删除线',
  'ql-script': '上标/下标',
  'ql-underline': '下划线',
  'ql-blockquote': '引用',
  'ql-header': '标题',
  'ql-indent': '缩进',
  'ql-list': '列表',
  'ql-align': '文本对齐',
  'ql-direction': '文本方向',
  'ql-code-block': '代码块',
  'ql-formula': '公式',
  'ql-image': '图片',
  'ql-video': '视频',
  'ql-clean': '清除字体样式'
}

export default {
  name: "Editor",
  props: {
    /* 编辑器的内容 */
    value: {
      type: String,
      default: "",
    },
    /* 高度 */
    height: {
      type: Number,
      default: null,
    },
    /* 最小高度 */
    minHeight: {
      type: Number,
      default: null,
    },
    /* 只读 */
    readOnly: {
      type: Boolean,
      default: false,
    },
    // 上传文件大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    /* 类型（base64格式、url格式） */
    type: {
      type: String,
      default: "url",
    },
  },
  data() {
    return {
      uploadUrl:
        baseURL[process.env.NODE_ENV + ""] + "/api/UpLoadFiles/UploadNewsImg", // 上传的图片服务器地址
      headers: {
        Authorization:
          "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
      },
      Quill: null,
      currentValue: "",
      options: {
        theme: "snow",
        bounds: document.body,
        debug: "warn",
        modules: {
          // 工具栏配置
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ],
          // 设置拖拽
          imageDrop: true,
          // 图片缩放
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
        placeholder: "请输入内容",
        readOnly: this.readOnly,
      },
    };
  },
  computed: {
    styles() {
      let style = {};
      if (this.minHeight) {
        style.minHeight = `${this.minHeight}px`;
      }
      if (this.height) {
        style.height = `${this.height}px`;
      }
      return style;
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val !== this.currentValue) {
          this.currentValue = val === null ? "" : val;
          if (this.Quill) {
            this.Quill.pasteHTML(this.currentValue);
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.init();
    //鼠标移动加提示
    this.addQuillTitle();
  },
  beforeDestroy() {
    this.Quill = null;
  },
  methods: {
    init() {
      const editor = this.$refs.editor;
      this.Quill = new Quill(editor, this.options);
      // 如果设置了上传地址则自定义图片上传事件
      if (this.type == "url") {
        let toolbar = this.Quill.getModule("toolbar");
        toolbar.addHandler("image", (value) => {
          this.uploadType = "image";
          if (value) {
            this.$refs.upload.$children[0].$refs.input.click();
          } else {
            this.quill.format("image", false);
          }
        });
      }
      this.Quill.pasteHTML(this.currentValue);
      this.Quill.on("text-change", (delta, oldDelta, source) => {
        const html = this.$refs.editor.children[0].innerHTML;
        const text = this.Quill.getText();
        const quill = this.Quill;
        this.currentValue = html;
        this.$emit("input", html);
        this.$emit("on-change", { html, text, quill });
      });
      this.Quill.on("text-change", (delta, oldDelta, source) => {
        this.$emit("on-text-change", delta, oldDelta, source);
      });
      this.Quill.on("selection-change", (range, oldRange, source) => {
        this.$emit("on-selection-change", range, oldRange, source);
      });
      this.Quill.on("editor-change", (eventName, ...args) => {
        this.$emit("on-editor-change", eventName, ...args);
      });
    },
    // 上传前校检格式和大小
    // handleBeforeUpload(file) {
    //   // 校检文件大小
    //   if (this.fileSize) {
    //     const isLt = file.size / 1024 / 1024 < this.fileSize;
    //     if (!isLt) {
    //       this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
    //       return false;
    //     }
    //   }
    //   return true;
    // },
    // handleUploadSuccess(res, file) {
    //   // 获取富文本组件实例
    //   let quill = this.Quill;
    //   // 如果上传成功
    //   if (res.code == 200) {
    //     // 获取光标所在位置
    //     let length = quill.getSelection().index;
    //     // 插入图片  res.url为服务器返回的图片地址
    //     quill.insertEmbed(
    //       length,
    //       "image",
    //       baseURL[process.env.NODE_ENV + ""] + "/NewsUploads/" + res.value
    //     );
    //     // 调整光标到最后
    //     quill.setSelection(length + 1);
    //   } else {
    //     this.$message.error("图片插入失败");
    //   }
    // },
    handleUploadError() {
      this.$message.error("图片插入失败");
    },

    // 图片上传
    uploadFile(e) {
      if (e.file) {
        compressImage(e.file).then((result) => {
          if (result.size > e.file.size) {
            // 压缩后比原来更大，则将原图上传
            //调接口上传图片到服务器
            this.uploadFileReq(e.file);
          } else {
            // 压缩后比原来小，上传压缩后的
            //调接口上传图片到服务器
            this.uploadFileReq(result);
          }
        });
      }
    },
    uploadFileReq(file) {
      var formData = new FormData();
      formData.append("files", file);
      postPic("/api/UpLoadFiles/UploadNewsImg", formData)
        .then((res) => {
          // 获取富文本组件实例
          let quill = this.Quill;
          // 如果上传成功
          if (res.code == 200) {
            // 获取光标所在位置
            let length = quill.getSelection().index;
            // 插入图片  res.url为服务器返回的图片地址
            quill.insertEmbed(
              length,
              "image",
              baseURL[process.env.NODE_ENV + ""] + "/NewsUploads/" + res.value
            );
            // 调整光标到最后
            quill.setSelection(length + 1);
          } else {
            this.$message.error("图片插入失败");
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles();
          this.$message.error("上传失败");
        });
    },


    //鼠标移动加提示
    addQuillTitle() {
      const oToolBar = document.querySelector('.ql-toolbar')
      const aButton = oToolBar.querySelectorAll('button')
      const aSelect = oToolBar.querySelectorAll('select')
      aButton.forEach(function (item) {
        if (item.className === 'ql-script') {
          item.value === 'sub' ? item.title = '下标' : item.title = '上标'
        } else if (item.className === 'ql-indent') {
          item.value === '+1' ? item.title = '向右缩进' : item.title = '向左缩进'
        } else {
          item.title = titleConfig[item.className]
        }
      })
      // 字体颜色和字体背景特殊处理，两个在相同的盒子
      aSelect.forEach(function (item) {
        if (item.className.indexOf('ql-background') > -1) {
          item.previousSibling.title = titleConfig['ql-background']
        } else if (item.className.indexOf('ql-color') > -1) {
          item.previousSibling.title = titleConfig['ql-color']
        } else {
          item.parentNode.title = titleConfig[item.className]
        }
      })
    },

    handlePaste(evt) {
      let that = this
      if (evt.clipboardData &&
        evt.clipboardData.files &&
        evt.clipboardData.files.length) {
        evt.preventDefault();
        [].forEach.call(evt.clipboardData.files, (file) => {
          if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
            return;
          }
          const formData = new FormData();
          compressImage(file).then((result) => {
            if (result.size > file.size) {
              formData.append("file", e.file);
            } else {
              formData.append("file", result);
            }
            // 实现上传
            $.ajax({
              type: "post",
              url: that.uploadUrl, // 上传的图片服务器地址
              data: formData,
              headers: that.headers,//必须
              dataType: "json",
              processData: false,
              contentType: false,//设置文件上传的type值，必须
              success: (response) => {
                if (response.code == 200 || response.code == 0) {
                  //当编辑器中没有输入文本时，这里获取到的 range 为 null
                  // 获取富文本组件实例
                  let quill = that.Quill;
                  var range = quill.selection.savedRange;
                  //图片插入在富文本中的位置
                  var index = 0;
                  if (range == null) {
                    index = 0;
                  } else {
                    index = range.index;
                  }
                  //将图片链接插入到当前的富文本当中
                  // quill.insertEmbed(index, "image", response.url);
                  quill.insertEmbed(
                    index,
                    "image",
                    baseURL[process.env.NODE_ENV + ""] + "/NewsUploads/" + response.value
                  );
                  // 调整光标到最后
                  quill.setSelection(index + 1); //光标后移一位
                }
              },
              error: function () {
                this.$message.error('上传失败！')
              },
            });
          });


        });
      }
    },
  },
};
</script>

<style>
.editor,
.ql-toolbar {
  white-space: pre-wrap !important;
  line-height: normal !important;
}

.quill-img {
  display: none;
}

.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
