<template>
  <div class="left-contact">
    <div>
      <div>
        <img v-if="showContact1" @click="clickContact(1)" src="../../../assets/images/onepage11.png" alt="" />
        <img v-else @click="clickContact(1)" src="../../../assets/images/onepage1.png" alt="" />
      </div>
      <div class="dialog dialog1 dialog-triangle" v-if="showContact1">
        <img src="../../../assets/images/ContactInfo1.png" alt="" />
        <p>获取最新资料</p>
      </div>
    </div>
    <div>
      <div>
        <img v-if="showContact2" @click="clickContact(2)" src="../../../assets/images/onepage22.png" alt="" />
        <img v-else @click="clickContact(2)" src="../../../assets/images/onepage2.png" alt="" />
      </div>
      <div class="dialog dialog2 dialog-triangle" v-if="showContact2">
        <img src="../../../assets/images/onepage22.png" alt="" />
        <div>
          <p>24小时服务热心</p>
          <p>020-39984322</p>
        </div>
      </div>
    </div>
    <!-- <div
      style="cursor: pointer"
      @click="$router.push('/NewLogin?showRegister=true')"
    >
      <img src="../../assets/images/onepage3.png" alt="" />
      <p>注册体验</p>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showContact1: false,
      showContact2: false,
      carouselIndex: null,
    };
  },
  mounted() { },
  methods: {
    clickContact(index) {
      index == 1
        ? (this.showContact1 = !this.showContact1)
        : (this.showContact2 = !this.showContact2);
      index == 1 ? (this.showContact2 = false) : (this.showContact1 = false);
    },
    clickCarousel(path) {
      this.$router.push(path);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 320px) and (max-width: 767px) {
  .left-contact {
    display: none;
  }
}
.left-contact {
  position: fixed;
  right: 20px;
  bottom: 27%;
  text-align: end;
  z-index: 9999;

  img {
    width: 40px;
    margin-bottom: 5px;
  }

  .dialog {
    background-color: #edebeb;
    position: absolute;
    border-radius: 5px;
  }

  .dialog::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 10px solid transparent;
  }

  .dialog-triangle::after {
    top: 40%;
    left: 100%;
    border-left: 10px solid #edebeb;

  }

  >div:nth-of-type(1) {
    position: relative;

    .dialog1 {
      width: 100px;
      height: 120px;
      text-align: center;
      right: 60px;
      top: -37px;

      img {
        width: 80px;
        margin-top: 10px;
      }

      p {
        font-size: 10px;
        color: #2d2d2d;
      }
    }
  }

  >div:nth-of-type(2) {
    .dialog2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 170px;
      height: 65px;
      position: absolute;
      right: 60px;
      top: 30px;

      p:nth-of-type(2) {
        color: #3f6dc4;
        font-weight: bold;
        font-size: 14px;
        margin-top: 2px;
      }

      img {
        width: 25px;
        margin-right: 10px;
      }
    }
  }

  >div:nth-of-type(3) {
    width: 40px;
    position: absolute;
    display: flex;
    justify-content: center;
    right: 0;

    p {
      width: 20px;
      text-align: center;
      color: white;
      position: absolute;
      bottom: 15px;
      font-size: 11px;
    }
  }
}
</style>