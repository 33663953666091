import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
// 内训课图片
const trainImgs = [
  require("../assets/images/trainImages1.jpg"),
];

// 内训课内容
const trainHtml = {
  1: `<div class="newsText">
  <section data-role="outer" label="Powered by 135editor.com">
    <section data-role="paragraph" class="_135editor" style="height: 0px; overflow: hidden;"></section>
    <section class="_135editor" data-tools="135编辑器" data-id="us-4092435">
      <section
        style="box-sizing:border-box;width: 8px; margin: 5px; border-radius: 5px; display: inline-block; height: 25px; float: left; color: #fefefe; background-color: #2308bd;">
        <br>
      </section>
      <section style="display: inline-block; padding: 5px; box-sizing: border-box;">
        <p style="font-size: 18px;" hm_fix="176:178"><span
            style="color: rgb(35, 8, 189); font-size: 24px;"><strong>课程背景</strong></span></p>
      </section>
    </section>
    <p style="line-height: 1.75em;"><span
        style="line-height: 125%; font-size: 17px; font-family: 宋体, SimSun;">&nbsp;&nbsp;</span><span
        style="text-indent: 38px; caret-color: red; font-family: arial, helvetica, sans-serif; font-size: 16px;">发展数字经济已上升到国家战略的层面，也相应出台了相关的政策，可以预期将迎来企业数字化转型的热潮，在这样的环境下相信明智的企业宁愿在摸索中前行也不愿意被时代抛弃。为帮助企业数字化转型，景翔信息科技公司及时整理了过去数字化辅导的经验和思路，率先推出了数字化体系转型的系列课程。以下是一天的浓缩课程大纲，相信该课程能够给企业高管带来打赢未来的全新概念！</span>
    </p>
    <p style="line-height: 1.75em;"><span
        style="text-indent: 38px; caret-color: red; font-size: 18px; font-family: 宋体, SimSun;"><br></span></p>
    <section class="_135editor" data-role="paragraph">
      <section class="_135editor" data-tools="135编辑器" data-id="us-4092400">
        <section
          style="box-sizing: border-box; width: 8px; margin: 5px; border-radius: 5px; display: inline-block; height: 25px; float: left; color: rgb(254, 254, 254); background-color: rgb(35, 8, 189); overflow: hidden;">
        </section>
        <section style="display: inline-block; padding: 5px; box-sizing: border-box;">
          <p style="font-size: 18px;"><span
              style="color: rgb(35, 8, 189); font-size: 24px;"><strong>授课目的</strong></span></p>
        </section>
      </section>
      <ol class=" list-paddingleft-2" style="list-style-type: decimal;">
        <li>
          <p style="text-align: left; line-height: 1.75em;"><span
              style="font-size: 16px; font-family: arial, helvetica, sans-serif;"><span
                style="font-size: 16px; text-indent: 38px; caret-color: red;">了解数字化驱动的工业革命的特征</span><span
                style="font-size: 16px; caret-color: red;"></span></span></p>
        </li>
        <li>
          <p style="line-height: 1.75em;"><span
              style="text-indent: 38px; caret-color: red; font-size: 16px; font-family: arial, helvetica, sans-serif;">了解数字化转型的国家扶持政策</span>
          </p>
        </li>
        <li>
          <p style="line-height: 1.75em;"><span
              style="text-indent: 38px; caret-color: red; font-size: 16px; font-family: arial, helvetica, sans-serif;">了解数字体系的核心内容</span>
          </p>
        </li>
        <li>
          <p style="line-height: 1.75em;"><span
              style="text-indent: 38px; caret-color: red; font-size: 16px; font-family: arial, helvetica, sans-serif;">通过数字体系快速提升竞争力</span>
          </p>
        </li>
        <li>
          <p style="line-height: 1.75em;"><span
              style="text-indent: 38px; caret-color: red; font-size: 16px; font-family: arial, helvetica, sans-serif;">企业体系数字化转型路径</span>
          </p>
        </li>
        <li>
          <p style="line-height: 1.75em;"><span
              style="text-indent: 38px; caret-color: red; font-size: 16px; font-family: arial, helvetica, sans-serif;">掌握实操方法并应用在转型中</span>
          </p>
        </li>
      </ol>
      <section class="_135editor" data-role="list">
        <p style="line-height:125%"><br></p>
      </section>
    </section>
    <section class="_135editor" data-tools="135编辑器" data-id="us-4092400">
      <section
        style="box-sizing: border-box; width: 8px; margin: 5px; border-radius: 5px; display: inline-block; height: 25px; float: left; color: rgb(254, 254, 254); background-color: rgb(35, 8, 189); overflow: hidden;">
      </section>
      <section style="display: inline-block; padding: 5px; box-sizing: border-box;">
        <p style="font-size: 18px;"><span style="color: rgb(35, 8, 189); font-size: 24px;"><strong>授课对象</strong></span>
        </p>
      </section>
    </section>
    <p><span
        style="text-indent: 38px; caret-color: red; font-size: 16px; font-family: arial, helvetica, sans-serif;">所有企业高层及有志于了解数字化体系转型的主管和管理人员</span>
    </p>
    <p><br></p>
    <p><br></p>
    <p><br></p>
    <p><br></p>
    <section class="_135editor" data-role="paragraph">
      <p><br></p>
    </section>
  </section>
  <p><br></p>
</div>`,
  2: `<div class="newsText">
  <section data-role="outer" label="Powered by 135editor.com">
    <section class="_135editor" data-role="paragraph">
      <p><br></p>
    </section>
    <section class="_135editor" style="text-align: left;" data-tools="135编辑器" data-id="us-4092435">
      <section
        style="margin: 5px; border-radius: 5px; width: 8px; height: 25px; color: rgb(254, 254, 254); overflow: hidden; float: left; display: inline-block; box-sizing: border-box; background-color: rgb(35, 8, 189);">
      </section>
      <section style="padding: 5px; display: inline-block; box-sizing: border-box;">
        <p style="font-size: 18px;" hm_fix="176:178"><span style="color: rgb(35, 8, 189);"><span
              style="font-size: 19px;"><strong>课程背景</strong></span></span></p>
      </section>
    </section>
    <section class="_135editor" data-role="paragraph">
      <p style="text-align: left; text-indent: 38px; line-height: 1.75em;"><span
          style="font-size: 18px; text-indent: 28px; caret-color: red; font-family: 宋体, SimSun;">良好的绩效管理体系可以激发员工持续改进业绩的动力，在企业内部形成优胜劣汰的竞争机制，提升个人绩效和组织整体绩效，打造企业可持续发展的能力。<br></span>
      </p>
      <p
        style="text-align: left; text-indent: 28px; margin-top: 0px; margin-right: 9px; margin-bottom: 0px; line-height: 1.75em;">
        <span
          style="line-height: 125%; font-size: 18px; font-family: 宋体, SimSun;">许多管理者在运用绩效管理工具去激发组织和员工时，往往只关注绩效考核，而忽略企业的战略导向、结构&amp;流程优化、组织能力建设等方面的系统协同作用，因而收效甚微，甚至引发激励的“负作用”；如何选择科学有效的绩效管理方式成为企业管理者和HR的一大难题。我们只有深刻理解的人力资源管理的底层逻辑以及各业务模块的关联关系，从顶层设计思维推动绩效管理驱动业绩增长，方可事半功倍。</span>
      </p>
      <p style="line-height: 1.75em;"><span style="line-height: 125%; font-size: 18px; font-family: 宋体, SimSun;">&nbsp;
          景翔咨询高级顾问柯贤知创建“绩效驱动轮”管理模型，将围绕以“创造顾客价值”为核心的管理理念，首次将“过程方法”应用于绩效管理活动研究，将绩效管理与主业务流程相融合，强化了企业运营与人力资源管理专业模块的相互关联；以绩效管理为抓手，以实现战略目标为导向，促使组织架构、岗位职责、人员配置科学合理，让流程增值，人员增效，增强组织业绩增长的持续动力。以下是两天的浓缩课程大纲，相信该课程能够给企业高管和HR带来的全新绩效管理思路！</span>
      </p>
      <p style="text-align: left; line-height: 125%; text-indent: 38px;"><br></p>
      <section class="_135editor" style="text-align: left;" data-tools="135编辑器" data-id="us-4092400">
        <section
          style="margin: 5px; border-radius: 5px; width: 8px; height: 25px; color: rgb(254, 254, 254); overflow: hidden; float: left; display: inline-block; box-sizing: border-box; background-color: rgb(35, 8, 189);">
        </section>
        <section style="padding: 5px; display: inline-block; box-sizing: border-box;">
          <p style="font-size: 18px;"><span
              style="color: rgb(35, 8, 189); font-size: 19px;"><strong>授课目的</strong></span></p>
        </section>
      </section>
      <section class="_135editor" data-role="list">
        <ol class=" list-paddingleft-2" style="list-style-type: decimal;">
          <li>
            <p style="line-height: 125%;"><span
                style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">在新的时代背景下，HR需要具备的知识和技能</span></p>
          </li>
          <li>
            <p style="line-height: 125%;"><span
                style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">HR核心职能以及采取绩效驱动模式助力企业提升业绩</span></p>
          </li>
          <li>
            <p style="line-height: 125%;"><span
                style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">运用过程方法的工作思维，推动企业管理精细化</span></p>
          </li>
          <li>
            <p style="line-height: 125%;"><span
                style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">走出绩效管理的认知误区，用增长性思维设计薪酬和绩效激励机制</span>
            </p>
          </li>
        </ol>
      </section>
      <p><br></p>
    </section>
    <section class="_135editor" style="text-align: left;" data-tools="135编辑器" data-id="us-4092400">
      <section
        style="margin: 5px; border-radius: 5px; width: 8px; height: 25px; color: rgb(254, 254, 254); overflow: hidden; float: left; display: inline-block; box-sizing: border-box; background-color: rgb(35, 8, 189);">
      </section>
      <section style="padding: 5px; display: inline-block; box-sizing: border-box;">
        <p style="font-size: 18px;"><span style="color: rgb(35, 8, 189); font-size: 19px;"><strong>授课对象</strong></span>
        </p>
      </section>
    </section>
    <section class="_135editor" data-role="paragraph">
      <p style="text-align: left; line-height: 125%;"><span
          style="font-family: 宋体, SimSun; caret-color: red; font-size: 18px;">企业各层级管理人员/人力资源从业人员</span><span
          style="line-height: 125%; font-family: 宋体, SimSun; font-size: 17px;"></span></p>
      <p style="text-align: left; line-height: 125%;"><br></p>
    </section>
  </section>
</div>`,
  3: `<div class="newsText">
  <section label="Powered by 135editor.com" data-role="outer">
    <section class="_135editor" style="height: 0px; overflow: hidden;" data-role="paragraph"></section>
    <section class="_135editor" style="text-align: left;" data-id="us-4092435" data-tools="135编辑器">
      <section
        style="margin: 5px; border-radius: 5px; width: 8px; height: 25px; color: rgb(254, 254, 254); overflow: hidden; float: left; display: inline-block; box-sizing: border-box; background-color: rgb(35, 8, 189);">
      </section>
      <section style="padding: 5px; display: inline-block; box-sizing: border-box;">
        <p style="font-size: 18px;" hm_fix="176:178"><span
            style="color: rgb(35, 8, 189); font-size: 19px;"><strong>课程背景</strong></span></p>
      </section>
    </section>
    <p style="line-height: 1.75em;"><span style="line-height: 125%; font-family: 宋体, SimSun; font-size: 17px;">&nbsp;
        &nbsp;<span
          style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">被誉为“全球质量管理大师”、“零缺陷之父”和“伟大的管理思想家”的菲利浦·克劳斯比在20世纪60年代初提出“零缺陷”思想，并在美国和日本推行零缺陷运动，使制造业迅速发展，质量领先世界。</span></span>
    </p>
    <p style="line-height: 1.75em;"><span
        style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">&nbsp;&nbsp;其中核心的缺陷分析工具则是——FMEA，可以帮助企业发现缺陷、分析缺陷、预防缺陷。该工具最早在航天航空、军工、汽车行业运用，如今越来越多企业为提高质量而应用此工具。</span>
    </p>
    <section class="_135editor" data-role="paragraph">
      <p style="text-align: left; line-height: 125%; text-indent: 38px;"><br></p>
      <section class="_135editor" style="text-align: left;" data-id="us-4092400" data-tools="135编辑器">
        <section
          style="margin: 5px; border-radius: 5px; width: 8px; height: 25px; color: rgb(254, 254, 254); overflow: hidden; float: left; display: inline-block; box-sizing: border-box; background-color: rgb(35, 8, 189);">
        </section>
        <section style="padding: 5px; display: inline-block; box-sizing: border-box;">
          <p style="font-size: 18px;"><span
              style="color: rgb(35, 8, 189); font-size: 19px;"><strong>授课目的</strong></span></p>
        </section>
      </section>
      <section class="_135editor" data-role="list">
        <p style="line-height: 150%;"><span
            style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">1.分析缺陷的起因和影响，并控制风险。</span></p>
        <p style="line-height: 150%;"><span
            style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">2.降低产品设计缺陷，提高产品可靠性</span></p>
        <p style="line-height: 150%;"><span
            style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">3.完善产品设计标准</span></p>
        <p style="line-height: 150%;"><span
            style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">4.提高产品制造合格率</span></p>
        <p style="line-height: 150%;"><span
            style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">5.完善工艺规范减少产品开发的后期变更</span></p>
        <p style="line-height: 150%;"><span
            style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">6.建立内部产品/工艺知识库</span></p>
      </section>
      <p><br></p>
    </section>
    <section class="_135editor" style="text-align: left;" data-id="us-4092400" data-tools="135编辑器">
      <section
        style="margin: 5px; border-radius: 5px; width: 8px; height: 25px; color: rgb(254, 254, 254); overflow: hidden; float: left; display: inline-block; box-sizing: border-box; background-color: rgb(35, 8, 189);">
      </section>
      <section style="padding: 5px; display: inline-block; box-sizing: border-box;">
        <p style="font-size: 18px;"><span style="color: rgb(35, 8, 189); font-size: 19px;"><strong>授课对象</strong></span>
        </p>
      </section>
    </section>
    <p><span style="font-family: 宋体, SimSun; caret-color: red; font-size: 18px;">企业负责人/产品设计工程师/工艺设计工程师/品质工程师</span></p>
  </section>
</div>`,
  4: `<div class="newsText">
  <section data-role="outer" label="Powered by 135editor.com">
    <section data-role="paragraph" class="_135editor" style="height: 0px; overflow: hidden;"></section>
    <section class="_135editor" data-tools="135编辑器" data-id="us-4092435">
      <section
        style="box-sizing: border-box; width: 8px; margin: 5px; border-radius: 5px; display: inline-block; height: 25px; float: left; color: rgb(254, 254, 254); background-color: rgb(35, 8, 189); overflow: hidden;">
      </section>
      <section style="display: inline-block; padding: 5px; box-sizing: border-box;">
        <p style="font-size: 18px;" hm_fix="176:178"><span
            style="color: rgb(35, 8, 189); font-size: 19px;"><strong>课程背景</strong></span></p>
      </section>
    </section>
    <p style="line-height: 1.75em;"><span style="line-height: 125%; font-size: 17px; font-family: 宋体, SimSun;">&nbsp;
        &nbsp;</span><span
        style="text-indent: 28px; caret-color: red; font-family: 宋体, SimSun; font-size: 18px;">质量是企业的生命，也是一个企业综合实力的体现。质量管理包括质量体系搭建、质量策划、质量控制、质量保证和质量改进。</span>
    </p>
    <p
      style="text-align: left; margin-top: 0px; margin-right: 9px; margin-bottom: 0px; text-indent: 28px; line-height: 1.75em;">
      <span
        style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">&nbsp;众所周知，航天航空和汽车行业是尤为重视产品质量，而许多质量管理的方法论和工具都是从这些行业诞生。质量管理五大核心工具正是在航天航空和汽车行业最早推行并应用，保证其持续生产出高质量产品的利器。</span>
    </p>
    <p
      style="text-align: left; margin-top: 0px; margin-right: 9px; margin-bottom: 0px; text-indent: 28px; line-height: 1.75em;">
      <span
        style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">现如今许多企业也认识到方法论和工具使用是对质量提升最快捷的途径，因此五大核心工具也被各行业企业应用在企业的质量管理上。</span>
    </p>
    <p
      style="text-align: left; margin-top: 0px; margin-right: 9px; margin-bottom: 0px; text-indent: 28px; line-height: 1.75em;">
      <span style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">五大核心工具分别是：</span>
    </p>
    <ol class=" list-paddingleft-2" style="list-style-type: decimal;">
      <li>
        <p style="text-align: left; margin-top: 0px; margin-right: 9px; margin-bottom: 0px; line-height: 1.75em;"><span
            style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">APQP（产品质量先期策划），是产品策划的项目管理，是新产品设计开发的质量策划的思路和方法。</span>
        </p>
      </li>
      <li>
        <p style="text-align: left; margin-top: 0px; margin-right: 9px; margin-bottom: 0px; line-height: 1.75em;"><span
            style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">FMEA（失效模式和后果分析）,是产品和工艺设计开发时，产品、生产过程失效分析的系统方法，可以有效发现、分析、预防缺陷。</span>
        </p>
      </li>
      <li>
        <p style="text-align: left; margin-top: 0px; margin-right: 9px; margin-bottom: 0px; line-height: 1.75em;"><span
            style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">SPC（统计过程控制），是一种借助数理通偶记方法的过程控制图工具，可以保持制程稳定性，降低不良率。</span>
        </p>
      </li>
      <li>
        <p style="text-align: left; margin-top: 0px; margin-right: 9px; margin-bottom: 0px; line-height: 1.75em;"><span
            style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">MSA（测量系统分析），是确定测量方法是否准确可靠的统计分析手段，可以提升测量准确性，降低质量风险。</span>
        </p>
      </li>
      <li>
        <section class="_135editor" data-role="list">
          <p style="text-align: left; margin-top: 0px; margin-right: 9px; margin-bottom: 0px; line-height: 1.75em;">
            <span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">PPAP（生产件批准程序），是用来确定新产品开发时，供应商的设计开发及生产过程是否满足客户要求的生产批准流程。</span>
          </p>
        </section>
      </li>
    </ol>
    <section class="_135editor" data-role="list"></section>
    <section class="_135editor" data-role="list"></section>
    <section class="_135editor" data-role="paragraph">
      <p style="text-align:left;text-indent: 38px; line-height: 125%;"><br></p>
      <section class="_135editor" data-tools="135编辑器" data-id="us-4092400">
        <section
          style="box-sizing: border-box; width: 8px; margin: 5px; border-radius: 5px; display: inline-block; height: 25px; float: left; color: rgb(254, 254, 254); background-color: rgb(35, 8, 189); overflow: hidden;">
        </section>
        <section style="display: inline-block; padding: 5px; box-sizing: border-box;">
          <p style="font-size: 18px;"><span
              style="color: rgb(35, 8, 189); font-size: 19px;"><strong>授课目的</strong></span></p>
        </section>
      </section>
      <ol class=" list-paddingleft-2" style="list-style-type: decimal;">
        <li>
          <p style="line-height: 1.75em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">能提升质量策划能力；</span></p>
        </li>
        <li>
          <p style="line-height: 1.75em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">能有效开展项目策划和管理，提升新产品导入流程有效性；</span></p>
        </li>
        <li>
          <p style="line-height: 1.75em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">掌握五大核心工具的理论知识和使用技巧；</span></p>
        </li>
        <li>
          <p style="line-height: 1.75em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">提升过程质量控制能力；</span></p>
        </li>
        <li>
          <section class="_135editor" data-role="list">
            <p style="line-height: 1.75em;"><span
                style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">提升产品/工艺设计的有效性。</span></p>
          </section>
        </li>
      </ol>
      <section class="_135editor" data-role="list"></section>
      <p><br></p>
    </section>
    <section class="_135editor" data-tools="135编辑器" data-id="us-4092400">
      <section
        style="box-sizing: border-box; width: 8px; margin: 5px; border-radius: 5px; display: inline-block; height: 25px; float: left; color: rgb(254, 254, 254); background-color: rgb(35, 8, 189); overflow: hidden;">
      </section>
      <section style="display: inline-block; padding: 5px; box-sizing: border-box;">
        <p style="font-size: 18px;"><span style="color: #2308bd; font-size: 19px;"><strong>授课对象</strong></span></p>
      </section>
    </section>
    <p style="line-height:125%"><span
        style="caret-color: red; font-family: 宋体, SimSun; font-size: 18px;">品质经理、研发经理、新产品导入工程师、质量工程师、产品工程师、供应商质量工程师</span>
    </p>
    <p><br></p>
    <section class="_135editor" data-role="paragraph">
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
    </section>
    <section class="_135editor" data-role="paragraph">
      <p><br></p>
    </section>
  </section>
  <p><br></p>
</div>`,
  5: `<div class="newsText">
  <section data-role="outer" label="Powered by 135editor.com">
    <section data-role="paragraph" class="_135editor" style="height: 0px; overflow: hidden;"></section>
    <section class="_135editor" data-tools="135编辑器" data-id="us-4092435">
      <section
        style="box-sizing:border-box;width: 8px; margin: 5px; border-radius: 5px; display: inline-block; height: 25px; float: left; color: #fefefe; background-color: #2308bd;">
        <br>
      </section>
      <section style="display: inline-block; padding: 5px; box-sizing: border-box;">
        <p style="font-size: 18px;" hm_fix="176:178"><span
            style="color: rgb(35, 8, 189); font-size: 19px;"><strong>课程背景</strong></span></p>
      </section>
    </section>
    <p style="line-height: 2em;"><span style="line-height: 125%; font-size: 17px; font-family: 宋体, SimSun;">&nbsp;
        &nbsp;</span><span
        style="text-indent: 38px; caret-color: red; font-family: 宋体, SimSun; font-size: 18px;">6S是当下工业界最佳的一种生产基础管理体系和方式，同时也是当今最先进的生产方式—精益生产的重要组成部分。但如何积极有效地推行6S现场管理，各个企业的管理者似乎既熟悉又陌生。目视管理，是2S整顿的体系方式及其扩展与延伸，但对于目视管理的实施标准，企业更没有建立统一规范、具体可行的标准。本课程通过介绍基本含义、实施步骤和细则标准，结合案例讲解企业单位推行实施6S与目视管理的方法，旨在有效帮助企业在生产作业现场及办公场所建立6S管理系统，为企业接下来的精益生产推进打下坚实的基础。</span>
    </p>
    <p><br></p>
    <section class="_135editor" data-role="paragraph">
      <section class="_135editor" data-tools="135编辑器" data-id="us-4092400">
        <section
          style="box-sizing: border-box; width: 8px; margin: 5px; border-radius: 5px; display: inline-block; height: 25px; float: left; color: rgb(254, 254, 254); background-color: rgb(35, 8, 189); overflow: hidden;">
        </section>
        <section style="display: inline-block; padding: 5px; box-sizing: border-box;">
          <p style="font-size: 18px;"><span
              style="color: rgb(35, 8, 189); font-size: 19px;"><strong>授课目的</strong></span></p>
        </section>
      </section>
      <ol class=" list-paddingleft-2" style="list-style-type: decimal;">
        <li>
          <p style="text-align: left; line-height: 1.5em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">认知6S基础管理概念与含义</span></p>
        </li>
        <li>
          <p style="text-align: left; line-height: 1.5em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">懂的6S推行策划方法</span></p>
        </li>
        <li>
          <p style="text-align: left; line-height: 1.5em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">掌握6S实施步骤与细则标准</span></p>
        </li>
        <li>
          <p style="text-align: left; line-height: 1.5em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">了解目视管理</span></p>
        </li>
        <li>
          <p style="text-align: left; line-height: 1.5em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">懂得目视管理各项通用标准与细则</span></p>
        </li>
      </ol>
      <section class="_135editor" data-role="list">
        <p style="line-height:125%"><br></p>
      </section>
    </section>
    <section class="_135editor" data-tools="135编辑器" data-id="us-4092400">
      <section
        style="box-sizing: border-box; width: 8px; margin: 5px; border-radius: 5px; display: inline-block; height: 25px; float: left; color: rgb(254, 254, 254); background-color: rgb(35, 8, 189); overflow: hidden;">
      </section>
      <section style="display: inline-block; padding: 5px; box-sizing: border-box;">
        <p style="font-size: 18px;"><span style="color: #2308bd; font-size: 19px;"><strong>授课对象</strong></span></p>
      </section>
    </section>
    <ol class=" list-paddingleft-2" style="list-style-type: decimal;">
      <li>
        <p style="line-height: 1.75em;"><span
            style="caret-color: red; font-family: 宋体, SimSun; font-size: 18px;">生产管理人员/生产作业现场的基层管理骨干/员工</span></p>
      </li>
      <li>
        <p style="text-align: left; line-height: 1.75em;"><span
            style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">企业6S现场管理策划者与推行者</span></p>
      </li>
      <li>
        <p style="text-align: left; line-height: 1.75em;"><span
            style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">需要实施6S的办公室及后勤部门的所有员工</span></p>
      </li>
    </ol>
    <p><br></p>
    <p><br></p>
    <section class="_135editor" data-role="paragraph">
      <p><br></p>
      <p><br></p>
    </section>
  </section>
</div>`,
  6: `<div class="newsText">
  <section data-role="outer" label="Powered by 135editor.com">
    <section data-role="paragraph" class="_135editor" style="height: 0px; overflow: hidden;"></section>
    <section class="_135editor" data-tools="135编辑器" data-id="us-4092435">
      <section
        style="box-sizing:border-box;width: 8px; margin: 5px; border-radius: 5px; display: inline-block; height: 25px; float: left; color: #fefefe; background-color: #2308bd;">
        <br>
      </section>
      <section style="display: inline-block; padding: 5px; box-sizing: border-box;">
        <p style="font-size: 18px;" hm_fix="176:178"><span
            style="color: rgb(35, 8, 189); font-size: 19px;"><strong>课程背景</strong></span></p>
      </section>
    </section>
    <p style="line-height: 1.75em;"><span style="line-height: 125%; font-size: 17px; font-family: 宋体, SimSun;">&nbsp;
      </span><span
        style="text-indent: 38px; caret-color: red; font-family: 宋体, SimSun; font-size: 18px;">“一流企业看中层，中层管理看班组”。班组是企业中最基层的组织，企业的所有关于运营和管理目标都要经过层层分解最后落实到班组.因而班组长管理管理素质的高低，直接影响企业管理的质量，产品质量、服务质量、安全生产与员工士气，直接关系到企业经营的成败。对于生产一线班组长，不仅要有较好的职业化操守和熟练的操作技能，同时要对生产相关的理念与管理技巧有很好掌握，包括如何杜绝浪费提高效率、保证安全生产、现场5S推行、员工质量安全意识提升。在实际管理工作中，许多企业老总往往重视对中高层的培训学习，而忽略对于基层班组长的专业化管理技能培训，从而导致管理“根基不稳”，本课程的推出恰好补齐了企业基层管理人员专业化培训的短板。</span>
    </p>
    <p style="line-height: 1.75em;"><span
        style="text-indent: 38px; caret-color: red; font-family: 宋体, SimSun; font-size: 18px;"><br></span></p>
    <section class="_135editor" data-role="paragraph">
      <section class="_135editor" data-tools="135编辑器" data-id="us-4092400">
        <section
          style="box-sizing: border-box; width: 8px; margin: 5px; border-radius: 5px; display: inline-block; height: 25px; float: left; color: rgb(254, 254, 254); background-color: rgb(35, 8, 189); overflow: hidden;">
        </section>
        <section style="display: inline-block; padding: 5px; box-sizing: border-box;">
          <p style="font-size: 18px;"><span
              style="color: rgb(35, 8, 189); font-size: 19px;"><strong>授课目的</strong></span></p>
        </section>
      </section>
      <ol class=" list-paddingleft-2" style="list-style-type: decimal;">
        <li>
          <p style="line-height: 1.5em;"><span style="font-size: 18px;"><span
                style="caret-color: red; font-family: 宋体, SimSun;">正确认识班组长的角色；</span><span
                style="line-height: 125%; font-family: 宋体, SimSun;"></span></span></p>
        </li>
        <li>
          <p style="line-height: 1.5em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">掌握管理基础知识和基础管理工具的应用；</span></p>
        </li>
        <li>
          <p style="line-height: 1.5em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">掌握班组长在工作和现场相关的十个方面管理要求；</span></p>
        </li>
        <li>
          <p style="line-height: 1.5em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">掌据现场管理各模块的相关知识和具体方法；</span></p>
        </li>
        <li>
          <p style="line-height: 1.5em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">了解现场改善的步骤与方法；</span></p>
        </li>
        <li>
          <p style="line-height: 1.5em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">了解班组日常管理要点以及方法</span></p>
        </li>
        <li>
          <p style="line-height: 1.5em;"><span
              style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">掌握基本沟通技能及团队建设工具</span></p>
        </li>
      </ol>
      <section class="_135editor" data-role="list">
        <p style="line-height:125%"><br></p>
      </section>
    </section>
    <section class="_135editor" data-tools="135编辑器" data-id="us-4092400">
      <section
        style="box-sizing: border-box; width: 8px; margin: 5px; border-radius: 5px; display: inline-block; height: 25px; float: left; color: rgb(254, 254, 254); background-color: rgb(35, 8, 189); overflow: hidden;">
      </section>
      <section style="display: inline-block; padding: 5px; box-sizing: border-box;">
        <p style="font-size: 18px;"><span style="color: #2308bd; font-size: 19px;"><strong>授课对象</strong></span></p>
      </section>
    </section>
    <p><span style="line-height: 125%; font-family: 宋体, SimSun; font-size: 18px;">一线班组长、骨干员工以及基层管理人员</span></p>
    <p><br></p>
    <section class="_135editor" data-role="paragraph">
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
    </section>
    <section class="_135editor" data-role="paragraph">
      <p><br></p>
    </section>
  </section>
  <p><br></p>
</div>`,
  7: `<div class="newsText">
  <p style="text-align: center;"><img src="${trainImgs[0]}" title="1680141677137228.png" alt="1.png" style="width:100%;"></p>
  <p><br></p>
  <p><br></p>
  <p><br></p>
  </div>`,
};

export default new Vuex.Store({
  state: {
    trainList: [
      {
        id: 1,
        title: "数字化体系",
        summary:
          "减少人手<br />减少成本<br />数字化转型<br />提升整体效率<br />快速提升竞争力",
        imgShow: require("../assets/images/innerTraining1.png"),
        imgHide: require("../assets/images/innerTraining11.png"),
        time: "2020-08-27",
        browse: "159",
        trainContent: trainHtml[1],
      },
      {
        id: 2,
        title: "绩效激励驱动业绩增长",
        summary:
          " 提高效率<br /> 以顾客为核心<br />让人员配置科学合理<br />让流程增值，人员增效<br />组织绩效驱动业绩增长",
        imgShow: require("../assets/images/innerTraining2.png"),
        imgHide: require("../assets/images/innerTraining22.png"),
        time: "2020-08-27",
        browse: "129",
        trainContent: trainHtml[2],
      },
      {
        id: 3,
        title: "产品零缺陷分析工具",
        summary:
          "追求产品零缺陷<br>降低产品设计缺陷<br>完善产品设计标准<br>第一次把事情做对<br>建立内部产品/工艺知识库<br>",
        imgShow: require("../assets/images/innerTraining3.png"),
        imgHide: require("../assets/images/innerTraining33.png"),
        time: "2020-08-27",
        browse: "123",
        trainContent: trainHtml[3],
      },
      {
        id: 4,
        title: "质量管理五大核心工具",
        summary:
          "提升质量策划能力<br />提升过程质量控制能力<br />提升新产品导入流程有效性<br />提升产品/工艺设计的有效性",
        imgShow: require("../assets/images/innerTraining4.png"),
        imgHide: require("../assets/images/innerTraining44.png"),
        time: "2020-08-27",
        browse: "128",
        trainContent: trainHtml[4],
      },
      {
        id: 5,
        title: "6S与目视管理",
        summary:
          "减少浪费<br />提升企业形象<br />安全保障的基础<br />标准化的有效推进",
        imgShow: require("../assets/images/innerTraining5.png"),
        imgHide: require("../assets/images/innerTraining55.png"),
        time: "2020-08-27",
        browse: "133",
        trainContent: trainHtml[5],
      },
      {
        id: 6,
        title: "班组长管理能力提升",
        summary:
          "增强班组凝聚力<br />提高团队建设的能力<br />掌握班组管理的技能与技巧<br />提高分析问题、解决问题的能力",
        imgShow: require("../assets/images/innerTraining6.png"),
        imgHide: require("../assets/images/innerTraining66.png"),
        time: "2020-08-27",
        browse: "119",
        trainContent: trainHtml[6],
      },
      {
        id: 7,
        title: "景翔2025年培训清单",
        summary: "<br />2025年景翔培训清单<br />点击查看更多",
        imgShow: require("../assets/images/innerTraining7.png"),
        imgHide: require("../assets/images/innerTraining77.png"),
        time: "2020-08-27",
        browse: "134",
        trainContent: trainHtml[7],
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
