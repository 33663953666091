import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home/index",
    meta: { title: "首页" },
    children: [
      {
        path: "/home/index",
        component: () => import("@/views/home/index"),
        meta: {
          title: "景翔科技-数字化转型丨企业管理咨询丨管理软件丨培训丨认证咨询",
          content: {
            keywords:
              "数字化管理体系,流程优化,价值链构建,零缺陷管理,管理软件,认证咨询",
            description:
              "景翔科技提供企业数字化转型顶层设计、战略落地、业务流程优化、管理体系搭建、标准化建设、质量零缺陷管理、等咨询和培训。2010年开始从事管理软件的开发和应用，将多年的管理经验和思想固化在管理软件上，让企业快速提升管理水平，致力于为企业提供 “ 咨询 + IT ” 一体化解决方案。",
          },
          keepAlive: true,
        },
      },
    ],
  },
  // 登录去文章编辑
  {
    path: "/login",
    component: () => import("@/views/login/index.vue"),
    meta: { title: "登录", hidden: true },
    children: [],
  },
  // 走进景翔
  {
    path: "/about",
    component: Layout,
    redirect: "/about/profile",
    meta: { title: "走进景翔" },
    children: [
      {
        path: "/about/profile",
        component: () => import("@/views/about/profile.vue"),
        meta: { title: "公司简介" },
      },
      {
        path: "/about/service",
        component: () => import("@/views/about/service.vue"),
        meta: { title: "服务理念" },
      },
      {
        path: "/about/honor",
        component: () => import("@/views/about/honor.vue"),
        meta: { title: "荣誉资质" },
      },
    ],
  },
  // 新闻中心
  {
    path: "/news",
    component: Layout,
    redirect: "/news/industry",
    meta: { title: "新闻中心" },
    children: [
      {
        path: "/news/industry",
        component: () => import("@/views/news/industry.vue"),
        meta: { title: "行业资讯" },
      },
      {
        path: "/news/company",
        component: () => import("@/views/news/company.vue"),
        meta: { title: "公司新闻" },
      },
      {
        path: "/news/newsDetail",
        component: () => import("@/views/news/newsDetail.vue"),
        meta: { title: "公司新闻详情", hidden: true },
      },
      {
        path: "/news/industryDetail",
        component: () => import("@/views/news/industryDetail.vue.vue"),
        meta: { title: "行业资讯详情", hidden: true },
      },
      {
        path: "/news/editor",
        component: () => import("@/views/news/editor.vue"),
        meta: { title: "新闻编辑", hidden: true },
      },
    ],
  },
  // 管理咨询
  {
    path: "/consult",
    component: Layout,
    redirect: "/consult/digitization",
    meta: { title: "管理咨询" },
    children: [
      {
        path: "/consult/digitization",
        component: () => import("@/views/consult/digitization.vue"),
        meta: { title: "数字化管理体系" },
      },
      {
        path: "/consult/flow",
        component: () => import("@/views/consult/flow.vue"),
        meta: { title: "流程优化" },
      },
      {
        path: "/consult/performance",
        component: () => import("@/views/consult/performance.vue"),
        meta: { title: "企业价值链重构" },
      },
      {
        path: "/consult/zeroDefect",
        component: () => import("@/views/consult/zeroDefect.vue"),
        meta: { title: "零缺陷管理" },
      },
      {
        path: "/consult/aswisercom",
        component: () => import("@/views/consult/aswisercom.vue"),
        meta: { title: "精益管理" },
      },
      {
        path: "/consult/construction",
        component: () => import("@/views/consult/construction.vue"),
        meta: { title: "管理体系建设" },
      },
      {
        path: "/consult/standardisation",
        component: () => import("@/views/consult/standardisation.vue"),
        meta: { title: "服务标准化建设" },
      },
      {
        path: "/consult/evaluate",
        component: () => import("@/views/consult/evaluate.vue"),
        meta: { title: "智能制造成熟度评估" },
      },
    ],
  },
  // 管理软件
  {
    path: "/software",
    component: Layout,
    redirect: "/software/fmea",
    meta: { title: "管理软件" },
    children: [
      {
        path: "/software/fmea",
        component: () => import("@/views/software/fmea.vue"),
        meta: { title: "FMEA管理系统" },
      },
      {
        path: "/software/perf",
        component: () => import("@/views/software/perf.vue"),
        meta: { title: "云体系落地平台" },
      },
      {
        path: "/software/regime",
        component: () => import("@/views/software/regime.vue"),
        meta: { title: "制度落地检查系统" },
      },
      {
        path: "/software/project",
        component: () => import("@/views/software/project.vue"),
        meta: { title: "工程管理系统" },
      },
      {
        path: "/software/equipment",
        component: () => import("@/views/software/equipment.vue"),
        meta: { title: "设备管理系统" },
      },
      {
        path: "/software/laundry",
        component: () => import("@/views/software/laundry.vue"),
        meta: { title: "洗衣管理系统" },
      },
    ],
  },
  // 管理培训
  {
    path: "/train",
    component: Layout,
    redirect: "/train/innerTraining",
    meta: { title: "管理培训" },
    children: [
      {
        path: "/train/innerTraining",
        component: () => import("@/views/train/innerTraining.vue"),
        meta: { title: "内训课清单" },
      },
      {
        path: "/train/trainDetail",
        component: () => import("@/views/train/trainDetail.vue"),
        meta: { title: "内训课清单详情", hidden: true },
      },
      {
        path: "/train/digitalSystem",
        component: () => import("@/views/train/digitalSystem.vue"),
        meta: { title: "数字化体系" },
      },
      {
        path: "/train/drive",
        component: () => import("@/views/train/drive.vue"),
        meta: { title: "绩效驱动业绩增长" },
      },
      {
        path: "/train/product",
        component: () => import("@/views/train/product.vue"),
        meta: { title: "产品零缺陷分析" },
      },
      {
        path: "/train/sixS",
        component: () => import("@/views/train/sixS.vue"),
        meta: { title: "6S与目视管理" },
      },
      {
        path: "/train/tool",
        component: () => import("@/views/train/tool.vue"),
        meta: { title: "质量管理五大工具" },
      },
      {
        path: "/train/jobForeman",
        component: () => import("@/views/train/jobForeman.vue"),
        meta: { title: "班组长能力提升" },
      },
    ],
  },
  // 认证咨询
  {
    path: "/authentication",
    component: Layout,
    redirect: "/authentication/IATF16949",
    meta: { title: "认证咨询" },
    children: [
      {
        path: "/authentication/IATF16949",
        component: () => import("@/views/authentication/IATF16949.vue"),
        meta: { title: "IATF16949" },
      },
      {
        path: "/authentication/ISO50001",
        component: () => import("@/views/authentication/ISO50001.vue"),
        meta: { title: "ISO50001" },
      },
      {
        path: "/authentication/ISO27001",
        component: () => import("@/views/authentication/ISO27001.vue"),
        meta: { title: "ISO27001" },
      },
      {
        path: "/authentication/ISO9001",
        component: () => import("@/views/authentication/ISO9001.vue"),
        meta: { title: "ISO9001" },
      },
      {
        path: "/authentication/ISO14001",
        component: () => import("@/views/authentication/ISO14001.vue"),
        meta: { title: "ISO14001" },
      },
      {
        path: "/authentication/ISO45001",
        component: () => import("@/views/authentication/ISO45001.vue"),
        meta: { title: "ISO45001" },
      },
    ],
  },
  // 景翔研究
  {
    path: "/study",
    component: Layout,
    redirect: "/study/book",
    meta: { title: "景翔研究" },
    children: [
      {
        path: "/study/book",
        component: () => import("@/views/study/book.vue"),
        meta: { title: "景翔书籍", keepAlive: true },
      },
      {
        path: "/study/bookdDetail",
        component: () => import("@/views/study/bookdDetail.vue"),
        meta: { title: "书籍详情", hidden: true },
      },
    ],
  },
  // 联系我们
  {
    path: "/contact",
    component: Layout,
    redirect: "/contact/index",
    meta: { title: "联系我们" },
    children: [
      {
        path: "/contact/index",
        component: () => import("@/views/contact/index.vue"),
        meta: { title: "联系我们" },
      },
    ],
  },
  // 访问统计
  {
    path: "/visit",
    component: Layout,
    redirect: "/visit/index",
    meta: { title: "访问统计", hidden: true },
    children: [
      {
        path: "/visit/index",
        component: () => import("@/views/visit/index.vue"),
        meta: { title: "访问统计" },
      },
    ],
  },
  // 404页面
  {
    path: "/404",
    component: (resolve) => require(["@/views/error/404.vue"], resolve),
    meta: { title: "404", hidden: true },
    children: [],
  },
];

const router = new VueRouter({
  routes,
  // 切换页面滚动条归0
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

// 点击重复路径报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  if (to.matched.length == 0) return next("/404");
  const token = sessionStorage.getItem("token");
  if (to.path === "/home/index") {
    sessionStorage.setItem("menuPath", "/home/index");
    sessionStorage.setItem("menuName", "首页");
  }

  // 添加关键字与描述
  if (to.meta.content) {
    const head = document.getElementsByTagName("head")[0];
    // 删除现有名称为 "keywords" 和 "description" 的 meta 标签
    const existingKeywordsMeta = document.querySelector(
      'meta[name="keywords"]'
    );
    if (existingKeywordsMeta) {
      existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
    }
    const existingDescriptionMeta = document.querySelector(
      'meta[name="description"]'
    );
    if (existingDescriptionMeta) {
      existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
    }

    // 创建并设置新的关键词和描述 meta 标签
    const keywordsMeta = document.createElement("meta");
    keywordsMeta.setAttribute("name", "keywords");
    keywordsMeta.setAttribute("content", to.meta.content.keywords);
    head.appendChild(keywordsMeta); // 添加新的关键词 meta 标签

    const descriptionMeta = document.createElement("meta");
    descriptionMeta.setAttribute("name", "description");
    descriptionMeta.setAttribute("content", to.meta.content.description);
    head.appendChild(descriptionMeta); // 添加新的描述 meta 标签

    // 可选：如果需要，更新名为 "content" 的其他 meta 标签
    const contentMeta = document.querySelector('meta[name="content"]');
    if (contentMeta) {
      contentMeta.setAttribute("content", to.meta.content); // 更新 content 属性
    }
  }

  // 动态修改浏览器标签页名字
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if ((to.path === "/news/editor" || to.path === "/visit/index") && !token) {
    return next("/login");
  } else {
    return next();
  }
});

function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;

    if (route.path !== "*") {
      array.push(path);
    }

    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }

    return array;
  }, []);
}

// getRoutesList(router.options.routes, 'https://zigamiklic.com');
function getRoutesXML() {
  const list = getRoutesList(router.options.routes, "https://www.jeansawn.com/")
    .map((route) => `<url><loc>${route}</loc></url>`)
    .join("\r\n");
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}

// console.log(getRoutesXML())

export default router;
